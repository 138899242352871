import { combineReducers } from "redux";
import auth from "./slices/auth";
import venta from "./slices/venta";
import stock from "./slices/stock";
import ot from "./slices/ot";
import repuestos from "./slices/repuestos";
import users from "./slices/users";
import profiles from "./slices/profiles";
import brands from "./slices/brands";
import dealers from "./slices/dealers";
import monitoreo from "./slices/monitoreo";
import fileupload from "./slices/fileUpload";
import notifications from "./slices/notifications";
import notificationsNormalizada from "./slices/notificationsNormalizada";
import userSession from "./slices/userSession";
import updates from "./slices/updates";
import importers from "./slices/importers";
import typeUpdates from "./slices/typeUpdates";
import emailSupport from "./slices/emailSupport";
import bitacoraCargas from "./slices/bitacoraCargas";

const rootReducer = combineReducers({
  auth,
  venta,
  stock,
  ot,
  repuestos,
  users,
  profiles,
  brands,
  dealers,
  monitoreo,
  fileupload,
  notifications,
  notificationsNormalizada,
  userSession,
  updates,
  importers,
  typeUpdates,
  emailSupport,
  bitacoraCargas
});

export { rootReducer };
