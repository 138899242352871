import { createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "../../utils/axios";

const initialState = {
    loading: false,
    error: false,
    filters_data: {},
    data: {
        BitacoraCargas: [],
        total: 0,
    },
};

const slice = createSlice({
    name: "bitacoraCargas",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.loading = true;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        bitacoraCargas(state, action) {
            state.loading = false;
            state.data = {
                ...action.payload,
                bitacoraCargas: action.payload.bitacoraCargas.map((x) => {
                    return { ...x, key: x.id};
                }),
                total: action.payload.total,
            }
        }
    },
    report(state) {
        state.loading = false;
        state.report = true;
    }
});

export const { startLoading, hasError, bitacoraCargas, report } = slice.actions;

export function getBitacoraCargas({ page = 1, count = 10, filters = {}, orderField = {}, orderSort }){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/bitacora/cargas?page=${page}&count=${count}`;
            for (const property in filters) {
                url += `&${property}=${filters[property]}`;
            }

            if (orderField && orderSort) {
                for (const field in orderField) {
                    const fieldValue = orderField[field];
                    const orderValue = orderSort;
                    if (fieldValue !== 0) {
                        url += `&orderField=${field}&orderSort=${orderValue}`;
                    }
                }
            }

            const response = await axios.get(url);
            dispatch(slice.actions.bitacoraCargas(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError)
        }
    }
}

export function getReport({ filters = {} }){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/bitacora/report?`;
            let count = 0;
            for (const key in filters) {
                url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
                count++;
            }
            const result = await axios.get(url, { responseType: 'blob' });
            fileDownload(result.data, `bitacoraCargas${new Date().getTime()}.xlsx`);
            dispatch(slice.actions.report());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export default slice.reducer;