import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { useNavigate } from "react-router-dom";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  brands: [],
  access: [],
  dealersO: [],
  error: "",
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, brands, access, dealersO } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      brands,
      access,
      dealersO,
    };
  },
  LOGIN: (state, action) => {
    const { user, brands, access, dealersO } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      brands,
      access,
      dealersO,
    };
  },
  LOGOUT: (state) => ({
    ...initialState,
    isInitialized: true,
  }),
  RECOVERY: (state, action) => ({
    ...state,
    message: action.payload.message,
  }),
  ERROR: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
  UPDATE_USER: (state, action) => {
    const { user, brands, access, dealersO  } = action.payload;
    return {
      ...state,
      user,
      brands,
      access,
      dealersO,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  recovery: () => Promise.resolve(),
  updateUserContext: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const tokenRefresh = window.localStorage.getItem("tokenRefresh");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, tokenRefresh);
          const decoded = jwtDecode(accessToken);

          const user = {
            id: decoded.id,
            name: decoded.name,
            profile: decoded.profile,
            email: decoded.email,
            profileId: decoded.profileId,
            web_access: decoded.web_access,
            importer: decoded.importer,
            filter_preference: decoded.filter_preference,
            first_time: decoded.first_time,
            active_modal: decoded.active_modal
          };

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
              brands: decoded.brands || [],
              access: decoded.access || [],
              dealersO: decoded.dealersO || [],
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
              brands: [],
              access: [],
              dealersO: [],
            },
          });
        }
      } catch (err) {
        console.error("Error al inicializar: ", err);
        dispatch({
          type: "LOGOUT",
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post("/login", { email, password });
      const { token, tokenRefresh } = response.data;

      const decoded = jwtDecode(token);
      if (!decoded.web_access) {
        throw new Error("No tienes permisos para acceder a esta aplicación.");
      }

      setSession(token, tokenRefresh);

      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            id: decoded.id,
            name: decoded.name,
            profile: decoded.profile,
            email: decoded.email,
            profileId: decoded.profileId,
            web_access: decoded.web_access,
            importer: decoded.importer,
            filter_preference: decoded.filter_preference,
            first_time: decoded.first_time,
            active_modal: decoded.active_modal
          },
          brands: decoded.brands || [],
          access: decoded.access || [],
          dealersO: decoded.dealersO || [],
        },
      });
    } catch (error) {
      const errorMessage =
        error?.toString() ||
        error.message ||
        error.response?.data?.message ||
        "Algo salió mal durante el inicio de sesión.";
      dispatch({
        type: "ERROR",
        payload: {
          error: errorMessage,
        },
      });
    }
  };

  const clearError = () => {
    dispatch({
      type: "ERROR",
      payload: {
        error: "",
      },
    });
  };

  const recovery = async (email) => {
    try {
      const response = await axios.post("/recovery", { email });
      dispatch({
        type: "RECOVERY",
        payload: {
          message: response.data.message,
        },
      });
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Error durante el proceso de recuperación.";
      dispatch({
        type: "ERROR",
        payload: {
          error: errorMessage,
        },
      });
    }
  };

  const refreshInfoToken = async () => {
    try {
      const response = await axios.get("/refreshInfoToken");

      const newToken = response.data.token;

      window.localStorage.setItem("accessToken", newToken);

      setSession(newToken, window.localStorage.getItem("tokenRefresh"));

      const decoded = jwtDecode(newToken);
      dispatch({
        type: "UPDATE_USER",
        payload: {
          user: {
            id: decoded.id,
            name: decoded.name,
            profile: decoded.profile,
            email: decoded.email,
            profileId: decoded.profileId,
            web_access: decoded.web_access,
            importer: decoded.importer,
            filter_preference: decoded.filter_preference,
            first_time: decoded.first_time,
            active_modal: decoded.active_modal,
          },
          brands: decoded.brands || [],
          access: decoded.access || [],
          dealersO: decoded.dealersO || [],
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      console.error("Error al refrescar el token:", error);
      return Promise.reject(error);
    }
  };


  const logout = () => {
    setSession(null, null);
    dispatch({ type: "LOGOUT" });
    navigate("/login", { replace: true });
  };

  const updateUserContext = (user) => {
    dispatch({
      type: "UPDATE_USER",
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{ ...state, method: "jwt", login, logout, recovery, updateUserContext, clearError, refreshInfoToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
