import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {
    dealers: [],
    status: '',
    message: '',
  },
};

const slice = createSlice({
    name: "dealers",
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        dealers(state, action) {
            state.loading = false;
            state.data = {
                ...action.payload,
                ...state.data,
                dealers: action.payload.dealers.map((x) => {
                    return { ...x, key: x.id };
                }),
                total: action.payload.total,
            };
        },
        profiles(state, action) {
            state.loading = false;
            state.filters_data = {
                ...action.payload,
                ...state.filters_data,
                profiles: action.payload.profiles.map((x) => {
                    return { ...x, key: x.id };
                }),
            };
        },
        saveDealer(state, action) {
          state.loading = false;
          state.error = '';
          state.data = {
            ...state.data,
            status: action.payload.data.status,
            message: action.payload.data.message,
          }
        },
        resetMessage(state, action) {
          state.data = {
            ...state.data,
            status: '',
            message: '',
          };
        }
    },
});

export default slice.reducer;

export function getDealers({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/dealers/all?`;
      let count = 0;
      for (const property in filters) {
        url += count == 0 ? `${property}=${filters[property]}` : `&${property}=${filters[property]}`;
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealers(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createDealer(dealer, refreshInfoToken) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {        
        let url = `/dealers/create`;
        const response = await axios.post(url, dealer);
        setTimeout(() => {
          dispatch(slice.actions.resetMessage());
        }, 5000);

        if(refreshInfoToken){
          await refreshInfoToken();
        }
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function updateDealer(dealer, refreshInfoToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {        
      let url = `/dealers/update`;
      const response = await axios.put(url, dealer);
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);

      if(refreshInfoToken){
        await refreshInfoToken();
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}